<template>
    <div>
        <main-layout></main-layout>
        <v-container class="wrapper">
            <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
                <v-row>
                    <v-col md="5" class="mx-auto">
                        <label class="mt-3">{{ $t("createQR.amount") }}</label>
                        <span class="star-require"></span>
                        <input class="form-control form-control-submit" v-model="amount" type="number"/>
                        <label class="mt-3">{{ $t("createQR.receiptNo") }}</label>
                        <span class="star-require"></span>
                        <input class="form-control form-control-submit" v-model="receiptNo" type="number"/>
                        <div v-if="stores.length > 0">
                            <label class="mt-3">{{ $t("createQR.store") }}</label>
                            <span class="star-require"></span>
                            <select v-model="storeCode" class="form-control form-control-submit">
                                <option v-for="s in stores" :key="s.id" :value="s.code">{{ s.name }}</option>
                            </select>
                        </div>
                        <div v-if="stores.length == 0">
                            <label class="mt-3">{{ $t("createQR.storeCode") }}</label>
                            <span class="star-require"></span>
                            <input class="form-control form-control-submit" v-model="storeCode" type="text"/>
                        </div>
                        <label class="mt-3">{{ $t("createQR.openDate") }}</label>
                        <span class="star-require"></span>
                        <div>
                            <date-picker :formatter="momentFormat" :editable="false" format='DD-MM-YYYY' v-model="openDate" />
                        </div>
                        <div class="row mt-5">
                            <div class="col-6">
                                <button class="button--secondary" @click="back">{{ $t("button.back") }}</button>
                            </div>
                            <div class="col-6">
                                <button class="button--primary button--right" @click="generateQR">{{ $t("button.generateQR") }}</button>
                            </div>
                        </div>
                    </v-col>
                    <v-col md="5" v-if="qrData != null">
                        <qr-code
                            :text="qrData"
                            :size="200"
                        ></qr-code>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
        </v-container>
        <loader v-show="loading"></loader>
    </div>
</template>
<script>
import MainLayout from "@/layout/MainLayout.vue";
import screen from "@/mixins/screen.js";
import 'vue2-datepicker/index.css';
import { mapState, mapGetters } from "vuex";
import popup from "@/components/mixins/popup";
import Loader from "@/components/Loader.vue";
import VueQRCodeComponent from "vue-qrcode-component";
import DatePicker from 'vue2-datepicker';
import moment from "moment";

export default {
    name: "CreateQR",
    components: {
        MainLayout,
        Loader,
        "qr-code": VueQRCodeComponent,
        DatePicker
    },
    mixins: [screen, popup],
    // mixins: [useVerifyOTP, popup],

    data() {
        return {
            amount: null,
            receiptNo: null,
            storeCode: '',
            posNo: "001",
            openDate: new Date(),
        };
    },

    async mounted() {
        await this.$store.dispatch('createQR/getUserStore');
    },
    // beforeRouteEnter(to, from, next) {
    //     window.scrollTo(0,0);
    //     store.state.auth.allowAnonymous = false;
    //     next();
    // },
    watch: {
        stores(newVal){
            this.storeCode = newVal[0]?.code || '';
        }
    },
    computed: {
        ...mapState(["auth"]),
        ...mapGetters({
            authDataGet: "auth/authDataGet",
            qrData: "createQR/qrData",
            stores: "createQR/stores",
            error: "createQR/error",
        }),
        isValidAmount() {
            return this.amount?.length > 0 && this.amount > 0;
        },
        isValidReceiptNo() {
            return this.receiptNo?.length > 0 && this.receiptNo > 0;
        },
        isValidStoreCode() {
            return this.storeCode.length > 0;
        },
    },
    methods: {
        validate() {
            if (!this.isValidAmount) {
                // this.$refs.amount.focus();
                return false;
            }
            
            if (!this.isValidReceiptNo) {
                // this.$refs.receiptNo.focus();
                return false;
            }

            if (!this.isValidStoreCode) {
                // this.$refs.storeCode.focus();
                return false;
            }

            return true;
        },
        back(e){
            e.preventDefault();
            return this.$router.push('/marketing');
        },

        async generateQR(e) {
            e.preventDefault();
            let error = '';

            const isValid = this.validate();
            if (!isValid) return;
            
            await this.$store.dispatch("createQR/generateQR", {
                amount: this.amount,
                receipt_no: this.receiptNo,
                store_code: this.storeCode,
                pos_no: this.posNo,
                open_date:  moment(this.openDate).format("YYYYMMDD"),
            })
            .catch(_error => {
                console.log(_error.message);
                error = _error.message;
            });
            

            if (this.auth.error) {
                this.loading = false;
                return this.$bvModal.msgBoxOk(
                    this.auth.error, 
                    this.messageBoxDanger
                );
            }
            else if(this.error){
                this.loading = false;
                return this.$bvModal.msgBoxOk(
                    this.error, 
                    this.messageBoxDanger
                );
            }
            else if(error.length){
                this.loading = false;
                return this.$bvModal.msgBoxOk(
                    this.$t(`message.${error}`), 
                    this.messageBoxDanger
                );
            } else {
                this.loading = false;
                return this.$bvModal.msgBoxOk(
                    this.$t("message.generateQRSuccess"),
                    this.messageBoxOK
                )
            }
        },
    },
};
</script>